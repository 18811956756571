<template lang="pug">
  div
    article.mt-7.mb-3
      p.text-sm.text-info_color.mx-auto.max-w-xs.text-center
        | Ingresa a continuación el número de formula,
      p.text-sm.text-info_color.mx-auto.max-w-xs.text-center
        | autorización o pre-autorización.
    form#formUserValidationAdd(@submit.prevent="handleContinue").mx-5
      input-component(
        v-model.number="documentNumber"
        id="documentNumber"
        name="documentNumber"
        type="text"
        mode="input"
        appendMore
      )
      p.text-base.text-primary_other.mx-auto.max-w-xs.text-center.mt-5
        | Adjunta foto o imágen de este documento
      div(v-if="files.length")
        transition-group(name="list" tag="div")
          .relative(
            v-for="(file, fileIndex) in files"
            :key="`${fileIndex}_${file.name}`"
          )
            img(
              v-if="file.objectUrl && (file.type == 'jpg' || file.type == 'png' || file.type == 'jpeg' || file.type == 'gif' || file.type == 'bmp')"
              :src="file.objectUrl"
              :alt="file.name"
            ).max-w-xs.my-5.mx-auto
            .my-5(v-else).text-center.max-w-xs-dk.box-border.h-64.bg-secondary.rounded-bd-medium.flex.items-center.p-5.justify-center.mx-auto.border-white
              p.text-white.box-border
                | {{ file.name }} no tiene una previsualización por lo que no es una imagen.
            button(
              @click="() => deleteFile(file, fileIndex)"
              type="button"
            ).border-none.flex.items-center.justify-center.absolute.manageCloseButton.bg-white.rounded-bd-complete.w-10.h-10
              .material-icons.text-info_color close
      br
      .text-danger_color.text-center
        label En caso de no digitar ningún número y no adjuntar un soporte, no se 
        label podrá asegurar la existencia del medicamento que desea reclamar en el
        label punto de dispensación seleccionado.
      br  
      .flex.m-8
        .relative.w-full.mr-3.cursor-pointer
          button-component(
            themeSelected="light"
            aditional="font-bold"
            icon="file_upload"
            type="button"
            sizeXLDK
          ).p-5
          input(
            type="file"
            @change="handleChangeFile"
          ).absolute.inputFile.opacity-0
        .relative.w-full.cursor-pointer
          button-component(
            type="button"
            themeSelected="light"
            aditional="font-bold"
            icon="add_a_photo"
            sizeXLDK
          ).p-5
          input(
            type="file"
            capture="camera"
            @change="handleChangeFile"
          ).absolute.inputFile.opacity-0
    modal-image-component(
      v-if="showModal"
      :closeModal="closeModal"
      @close-button="getHandleCloseButton"
    ).text-center
      .maxdkloading.flex.items-center.justify-center.mx-auto(v-if="loadingModal")
        p.text-white Cargando
        figure.ml-1.text-white.bg-white.p-0.p-5.rounded-bd-complete.w-10.h-10.mx-auto.text-center.flex.justify-center.items-center
          img.w-10.h-10.object-cover.maximagLoad(src="https://ntrens.com.ar/imagenes/cargando.gif" alt="loading")
      p(v-if="errorModal").p-5.text-white {{ errorModal }}
      img-cropper(
        v-if="urlData && !errorModal && !loadingModal && typeImage"
        :closeFullscreen="closeFullscreen"
        :openCropper="openCropper"
        :fullscreenOpened="fullscreenOpened"
        :handleCreateImage="handleCreateImage"
        :rotate="rotate"
        :urlData="urlData"
      )
      div(v-else)
        div.max-w-xs-dk.h-64.bg-secondary.rounded-bd-medium.flex.items-center.p-5.justify-center.mx-auto.border-white
          p.text-white {{ fileNoImage.name }} no tiene una previsualización por lo que no es una imagen.
        .flex
          button(
            @click="handleCreateImage"
          ).mt-4.mx-auto.ArrowBlack.rounded-bd-complete.w-10.h-10.flex.items-center.justify-center.border-none.outline-none
            span.material-icons-outlined.text-white check_circle
    footer.Footer.fixed.p-5.pt-2.max-w-lg.w-full
      button-component(
        text="Continuar"
        themeSelected="secondary"
        form="formUserValidationAdd"
        type="submit"
        :disabled="loading")
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "FormDocumentsAddHTML",
  data() {
    return {
      documentNumber: "",
    };
  },
  created() {
    if (this.formSelectedForEdit) {
      this.documentNumber = this.formSelectedForEdit.numero;
    }
  },
  watch: {
    documentNumber(val) {
      if (val) {
        this.$emit("change_model_doc", val);
      }
    },
  },
  props: [
    "loading",
    "closeFullscreen",
    "openCropper",
    "fullscreenOpened",
    "handleCreateImage",
    "rotate",
    "urlData",
    "errorModal",
    "loadingModal",
    "getHandleCloseButton",
    "closeModal",
    "value",
    "showModal",
    "handleChangeFile",
    "files",
    "handleContinue",
    "deleteFile",
    "typeImage",
    "fileNoImage",
  ],
  components: {
    ButtonComponent: () => import("../components/general/Button.vue"),
    InputComponent: () => import("../components/general/Input.vue"),
    ModalImageComponent: () => import("../components/ModalImage.vue"),
    ImgCropper: () => import("../components/ImgCropper.vue"),
  },
  computed: {
    ...mapState({
      formSelectedForEdit: (state) => state.formDocuments.formSelectedForEdit,
    })
  },
}
</script>

<style scoped>
.inputFile {
  width: 100%;
  max-width: 230px;
  height: 58px;
  top: 0;
  cursor: pointer;
}

.imageModal {
  max-height: 650px;
}

.ArrowBlack {
  background-color: rgba(129, 112, 112, 0.459);
}

.maximagLoad {
  max-width: 30px;
}

.maxdkloading {
  max-width: 45px;
}

.manageCloseButton {
  top: 0px;
  right: 20px;
}

.max-w-xs-dk {
  max-width: 18rem;
  width: 18rem;
}

.list-enter-active, .list-leave-active {
  transition: all 1s;
}

.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
